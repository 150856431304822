@font-face {
  font-family: "icons";
  src:url("../fonts/icons.eot");
  src:url("../fonts/icons.eot?#iefix") format("embedded-opentype"),
  url("../fonts/icons.woff") format("woff"),
  url("../fonts/icons.ttf") format("truetype"),
  url("../fonts/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AnotherShabby';
  src: url("../fonts/Anothershabby.ttf")
}

.color-00c400 {
  color: #00c400 !important;
}
.color-fd9a54 {
  color: #fd9a54 !important;;
}
.color-d00e0e {
  color: #d00e0e !important;;
}
.from-consultant-mobile  img {
  border-radius: 30px;
  max-width: 32px;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
[data-icon-m]:before {
  font-family: "icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-m"]:before,
[class*=" icon-m"]:before {
  font-family: "icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-m-home:before {
  content: "\61";
}
.icon-m-user:before {
  content: "\62";
}
.icon-m-usd:before {
  content: "\63";
}
.icon-m-calculator:before {
  content: "\64";
}
.icon-m-help:before {
  content: "\65";
}
.icon-m-file-text:before {
  content: "\66";
}

.login {

}
.universe {

}
.universe img {
  display: block;
  width: 79%;
}
.form-group-visa {
  display: block !important;
}
.login-box {
  position: absolute;
  top: 25%;
  background-color: #2a576a;
  color: #94b9c8;
}
.login-inscription {
  top: 19%;
}
.register-box {
  position: absolute;
  top: 11%;
  background-color: #2a576a;
  color: #94b9c8;
}
.form-group {
  margin-bottom: 20px;
  overflow: auto;
}
.form-control-login {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #ffffff;
  background-color: #17323d;
  background-image: none;
  border: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.form-control-visa {
  display: inline-block;
  width: 49%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #ffffff;
  background-color: #17323d;
  background-image: none;
  border: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
/*input.form-control-login {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    !* color: #b96665; *!
    background-color: #17323d;
    background-image: none;
    border: 0;
}*/
.panel-heading {
  font-size: 26px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 400;
  color: #fa6420;
}
.btn-login {
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  background-color: #2a576a;
  font-weight: 400;
  transition:.5s;
}
.btn-login:hover {
  background-color: #c9db50;
  border-color: #c9db50;
}
.heading-no-margin {
  margin: 0;
}
.panel-body {
  margin-top: 0;
}
.panel-borderless {
  border: 0 !important;
  box-shadow: none !important;
}
.my-account {
  text-align: right;
  color: #fff;
  padding-top: 6px;
  padding-right: 45px;
}
.my-account span {

}
.consultant {
  text-align: center;
  padding-top: 20px;
}
.consultant .extras {
  text-align: left;
}

.consultant .extras span {

}
.consultant .about *{
  font-size: 14px;
  text-align: justify;
}
.consultant h2 ,.social h2{
  margin-top: 96px;
}
.consultant-image h3 {
  text-align: center;
  color: #2a576a;
}
.consultant-info p {
  text-align: justify;
  padding: 0 15px;
}
/*.social-buttons img{
    width: 44%;
    display: block;
    margin: 0 auto;
}*/
.weather {
  text-align: center;
  margin-top: 40px;
}
.weather h4 {
  width: 125px;
  margin: 0 auto;
}

.img-profile {
  width: 30px;
  height: 30px;
  display: inline-block;
  color: #fff;
  position: relative;
  top: 10px;
  left: 10px;
}
.img-consultant {
  width: 69%;
  display: block;
  margin: 0 auto;
}
.img-profile-small {
  top:0;
}
.img-block {
  display: block;
  margin: 0 auto;
}

.img-profile span {
  position: absolute;
  right: 9px;
  top: 4px;
  font-size: 16px;
  font-weight: 400;
}
.profile-student ,.profile-consultant{
  text-align: center;
}
.profile-student h3 {

}
.profile-student h5 {

}

.payment-plan {
  /*min-height: 326px;*/
}
.overview {

}
.overview .inner {
  border: 2px solid #fa6420;
  min-height: 325px;
  padding: 0 10px;
}
.overview .inner h3 {
  text-align: center;
}
.my-account-details {
  margin-top: 25px;
}
/* Menu */
.bar-student {
  background-color: #fc6300;
}
.menu-student-mobile {
  display: none;
  position: fixed;
  bottom: 0;
  z-index: 10000;
  background-color: #efefef;
  width: 100%;
}
.menu-student {
  padding: 0;
  width: 100%;
  display: table;
  table-layout: fixed; /* optional */
  text-align: center;
}

.menu-student li {
  display: table-cell;
  width: auto;
  text-align: center;
  padding-top: 4px;

  /*padding: 3px 13px;*/
}
.menu-student li a {
  color: #259D7F;
  font-weight: 400;
}
.menu-student li a .icon-menu {
  display: block;
  margin: 0 auto;
}
.menu-student li a .icon-menu i {
  font-size: 30px;
  vertical-align: bottom;
}
.menu-student li a span{
  font-size: 10px;
  display: block;
}
.menu-student li.active {
  /* padding: 2px 0 !important;*/
}
.menu-student li a:hover {
  color: #8ca000;
}
.menu-student li.active a{
  color: #8ca000;
}

.my-account-box {
  display: none;
}
.my-account-box {
  width: 300px;
  height: 139px;
  position: absolute;
  right: 11px;
  margin: 0;
  color: #fc6300;
  top: 57px;
  background: -webkit-linear-gradient(#fff, #FDFDFD, #F7F7F7);
  background: linear-gradient(#fff, #FDFDFD, #F7F7F7);
  -webkit-box-shadow: 1px 1px 5px 1px rgba(194,194,194,1);
  -moz-box-shadow: 1px 1px 5px 1px rgba(194,194,194,1);
  box-shadow: 1px 1px 5px 1px rgba(194,194,194,1);
  z-index: 10;
}

.arrow-dashboard {
  width: 34px;
  height: 24px;
  position: absolute;
  right: 6px;
  top: -17px;
  background-image: url(../images/arrow-dashboard.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.button-ui {
  background-color: #fc6300;
  border: 0;
  display: inline-block;
  color: #fff;
  margin-top: 10px;
}
.box {
  position: relative;
  background: #ffffff;
  /*border-top: 3px solid #d2d6de;*/
  margin-bottom: 20px;
  width: 100%;
}
.box-header {
  color: #444;
  display: block;
  padding: 5px 0;
  /*padding: 10px;*/
  position: relative;
}
.box-header.with-border {
  border-bottom: 1px solid #f4f4f4;
}
.box-header>.box-tools {
  position: absolute;
  right: 10px;
  top: 11px;
}
.box-header>.fa, .box-header>.glyphicon, .box-header>.ion, .box-header .box-title {
  display: inline-block;
  font-size: 21px;
  margin: 0;
  line-height: 1;
  /*text-transform: uppercase;*/
}
.box-title {
  width: 75%;
}
.box-header .box-title-stage {
  text-transform: uppercase;
}
.box-stage {

}
.box-stage > .box-header {
  margin: 0 20px;
}
.bigger {
  width: 80px;
  height: 80px;

}
.bigger>span{
  font-size: 35px;
  right: 26px;
  top: 14px;
}
.centered {
  margin: 0 auto;
  float: none !important;
  display: block;
}
.process {
  overflow: hidden;
  /*height: 10px;*/
  height: 6px;
  /*margin-bottom: 20px;*/
  margin-top: 5px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid;
  display: inline-block;
  width:200px;
}
.process-stage {
  overflow: hidden;
  /*height: 10px;*/
  height: 10px;
  /*margin-bottom: 20px;*/
  margin-top: 5px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #259D7F;
  display: inline-block;
  width: 155px;
}
.process-total {
  overflow: hidden;
  /*height: 10px;*/
  height: 6px;
  /*margin-bottom: 20px;*/
  margin-top: 5px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid;
  display: inline-block;
  width: 176px;
}
.process-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #259D7F;
  -webkit-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
}
.percentage {
  color: #259D7F;
  font-size: 13px;
}
.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  /*padding: 10px;*/
}
.logout {
  background-color: #259D7F;
  height: 39px;
  text-align: center;
  padding-top: 9px;
}
.logout a {
  color: #fff;
  vertical-align: middle;
  border: 2px solid #fff;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 15px;
  transition: .5s;
}
.logout a:hover {

}

.payment-plans {

}
.payment-plan-header {
  margin-top: 1px;
}
.payment-plans-title {
  text-align: center;

}
.payment-plan-items {
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
}
.green {
  color: #03cb0d;
}
.items {
  margin-top: 18px;
}

.course-name {
  color: #2a576a;
  font-size: 18px;
  margin-left: 8px;
  font-weight: 400;
  text-align: center;
}




.logo-school {
  width: 100px !important;
}
.student-title h1 {
  color: #94b9c8;
  background-color: #17323d;
  text-transform: uppercase;
  font-weight: 100;
  padding: 2px 8px;
}
.due-payment {
  margin-top: 20px;
  border: 2px solid #259D7F;
  text-align: center;
}
.due {
  padding: 17px 5px;
}

.btn:hover {
  background-color: #c9db50;
  color: #fff;
}
.btn-pay {
  border: 2px solid #259D7F;
  background-color: #fff;
  color: #fff;
  font-size: 15px;
  font-weight: 200;
  transition: .5s;
}
.btn-payment {
  margin-top: 0 !important;
  padding: 5px 5px;
}

.btn-payment .fa {
  font-size: 16px;
}
.payment-plan-items .mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .payment-plan-items .desktop {
    display: none;
  }
  .payment-plan-items .mobile {
    display: block;
  }
  .btn-payment {
    margin-bottom: 5px;
    font-size: 11px;
  }
  .btn-payment .fa {
    font-size: 11px;
  }
}
.btn-orange {
  color: #fff;
  background-color: #fc6300;
  border: 2px solid #ff6200;
  transition: .5s;
}

.btn-orange:hover {
  text-decoration: none;
  background-color: #c9db50;
  color: #5D5D5D;

}
.btn-pay:hover {
  text-decoration: none;
}
.btn-med {
  width: 119px;
  font-size: 21px;
  border: 2px solid #259D7F;
}
.credit-card-box .panel-title {
  margin: 8px 0px;
}
.credit-card-box .total-payment {
  font-size: 16px;
  color: #17323d;
  text-decoration: underline;
}
.display-card {
  display: block;
  height: 46px;
  width: 32%;
}
img.loading {
  width: 130px;
  margin: 0 auto;
  /* display: block;*/
}
.display-none {
  display: none !important;
}
.new {
  width: 119px;
  margin: 16px 0;
}
.profile-box {
  height: 100px;
}
.student-box {
  text-align: left;
}
.student-box h5 {
  color: #2a576a;
  font-size: 17px;
  font-weight: 200;
  margin-top: 40px;
  margin-bottom: 0;
}
.box-document {
  box-shadow: 0 0 0 0 #ddd !important;
}
.disabled {
  opacity: 0.3;
  pointer-events: none;
}
.bg-success {
  background-color: #00a65a !important;
}
.bg-warning {
  background-color: #f39c12 !important;
}
.bg-danger {
  background-color: #dd4b39 !important;
}
.bg-grey-2 {
  background-color: #888888 !important;
  color: #FFF !important;
}
.color-ffffff {
  color: #ffffff !important;
}
.color-green-lemon-2 {
  color: #bbc106 !important;
}
.border-888 {
  border: 1px solid #888 !important;
}
.orange {
  color: #ff6200 !important;
}
.process-orange {
  background-color: #ff6200 !important;
}
.process-blue {
  background-color: #259D7F !important;
}
.web-blue {
  color: #5D5D5D !important;
}
.box-success {
  border-color: #00a65a !important;
}
.box-orange {
  border-color: #ff6200 !important;
}
.box-blue {
  border-color: #259D7F !important;
}
.box-warning {
  border-color: #f39c12 !important;
}
.box-danger {
  border-color: #dd4b39 !important;
}
/* support */
form {
  margin: 0;
}

input.form-control-login::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.5);
}
input.form-control-login::-moz-placeholder { /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.5);
}
input.form-control-login:-ms-input-placeholder { /* IE 10+ */
  color: rgba(255, 255, 255, 0.5);
}
input.form-control-login:-moz-placeholder { /* Firefox 18- */
  color: rgba(255, 255, 255, 0.5);
}

select.form-blue {
  background-color: #17323d !important;
  color: #fff !important;
  border-radius: 4px;
}
.select-style {
  padding: 0;
  margin: 0;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  color: #ffffff;
  background: #389c7f url("../images/select-arrow.png") no-repeat 90% 50%;
}

.select-style select {
  padding: 7px 8px;
  width: 103%;
  height: 34px;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select-style select:focus {
  outline: none;
}
.transacction-result {
  text-align: center;
  color: #17323d;
}
.transacction-result h2 {
  font-weight: 200;
  font-size: 26px;
  margin: 10px;
}
.transacction-result .invoice-paid-text {
  font-size: 21px;
}
.transacction-result .invoice-paid {
  font-size: 28px;
  font-weight: 500;

}
.borderless td, .borderless th, .borderless tr {
  border: none;
  border-top: none !important;
}

table.table-support {
  width: 100%;
}

table.table-support thead {
  background-color: #888;
  color: #fff;
}

table.table-support tr th {
  padding: 8px 16px;
  color: #fff;
}

table.table-support tr td {
  padding: 12px 19px;
}
.ticket-info ul {
  /*border: 1px solid #259D7F;*/
}
.ticket-info ul li{
  display: inline-block;
  padding: 4px 2px;
  width: 49%;
}
.create-ticket {
  padding: 5px;
}
.box-comments {
  background: #f7f7f7;
}
.box-comments .box-comment:first-of-type {
  padding-top: 0;
}
.box-comments .box-comment {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}
.img-sm, .box-comments .box-comment img, .user-block.user-block-sm img {
  width: 30px !important;
  height: 30px !important;
}
.img-sm, .img-md, .img-lg, .box-comments .box-comment img, .user-block.user-block-sm img {
  float: left;
}
.box-comments .box-comment img {
  float: left;
}
.box-comments .comment-text {
  margin-left: 40px;
  color: #555;
}
.box-comments .username {
  color: #444;
  display: block;
  font-weight: 600;
}
.box-comments .text-muted {
  font-weight: 400;
  font-size: 12px;
}
.message {
  margin-top: 11px;
}
.box-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 1px solid #f4f4f4;
  padding: 10px;
}

.profile-img{

}

.invoice {
  /*padding: 15px;*/
}
.invoice-header {
  height: 30px;
  background-color: #259D7F;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 5px;
}
.invoice-header button.close {

}

.invoice .invoice-logo {
  display: block;
  margin: 20px auto;
}
.invoice-logo img {
  display: block;
  margin: 0 auto;
  width: 170px;
}
button.close {

}
.invoice-info {
  /*margin-top: 20px;*/
  border-bottom: 3px solid #259D7F;
  padding-bottom: 5px;
  overflow: auto;
}
.invoice-footer {
  height: auto;
  background-color:#259d7f;
  text-align: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-top: 14px;
  padding-bottom: 14px;
}
.invoice-footer button {

}
table.table-item {
  width: 100%;
  text-align: center !important;
  margin: 20px 0;
}

table.table-item thead {
  border-bottom: 1px solid #c9db50;
}

table.table-item tr th {
  padding: 8px 16px;
  text-align: center;
}

table.table-item tr td {
  padding: 12px 19px;
}
@media (max-width: 768px){
  .invoice-info {
    font-size: 14px;
  }
  .item {
    font-size: 13px;
  }
  dd.total_item {
    font-size: 13px !important;
  }
}

.credit-card-form {
  display: none;
}
/*#pay-process-modal{
  display: none;
}
#pay-continue-modal{
  display: none;
}*/
.btn-modal-pay {
  width: 200px;
  border: 2px solid #fff;
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  transition: .5s;
  background: #259d7f;
}
.btn-modal-pay:hover {
  border: 2px solid #259d7f;
  background-color: #ffffff;
  color: #259d7f;
  text-decoration: none;
}
.form-sign {
  display: inline-block;
}
.reason {
  display: none;

}
.input-reason {
  display: block;

}
.total-process {
  margin-bottom: 10px;
  margin-top: 33px;
  width: 210px;
}
.total-process h3 {
  text-align: center;
  text-transform: uppercase;
  color: #259D7F;
  font-size: 17px;
}
.btn-blue {
  color: #fff;
  background-color: #2a576a;
  border-color: #2a576a;
}
.school {
  height: 300px;
  text-align: center;  /* align the inline(-block) elements horizontally */
  font: 0/0 a;         /* remove the gap between inline(-block) elements */
}

.school:before {    /* create a full-height inline block pseudo=element */
  content: ' ';
  display: inline-block;
  vertical-align: middle;  /* vertical alignment of the inline element */
  height: 100%;
}

.school img {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.bordered {
  border: 1px solid #17323d;
  padding-top: 15px;
}
.table-documents {
  margin-top: 20px;
}
.btn-sml {
  padding: 0 10px !important;
}
.upload-form {
  margin: 0 auto;
  text-align: center;
}
.upload-form span{
  font-weight: 600;

}
input#exampleInputFile {
  margin: 0 auto;
  width: 216px;
}
.col-md-11>.box-tools {
  margin: 21px auto;
  text-align: center;
  font-weight: 400;
}
.file-input {
  margin: 0 auto;
  width: 192px;
}
.completed img {
  width: 33px;
  display: block;
  margin: 49px auto 30px auto;
}
.incomplete img {
  width: 33px;
  display: block;
  margin: 49px auto 30px auto;
}
.single-post {
  height: 413px;
}
.white {
  color: #FFFFFF;
}
.message p {
  overflow: scroll;
}


.attachment-info {
  /*width: 50%;*/
  padding: 10px;
  background: #e8e8e8;
  white-space: nowrap;
  overflow: hidden;
}
.attachment-size {
  display: block;
}
.parallaxSystem > * {
  height: 120%;
  position: relative;
  top: 0;
  background-position: center center;
}
h2.rounded {
  color: #259D7F;
  font-size: 1.5em;
}

i.fa {
  font-size: 20px;
}
@media only screen and (min-width: 35em) {
  .parallaxBlog > * {
    top: -159% !important;
  }

}

.list-inline {
  padding-left: 0;
  list-style: none;
  text-align: center;
}
.list-inline>li {
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 0px;
  /*width: 50px;*/
}
.social-buttons img{
  max-width: 100%;
  display: block;
}
dl {
  margin-top: 0;
  margin-bottom: 20px;
}
.total {
  height: 111px;
  margin-right: 15px;
}
dd.total_item {
  font-size: 19px;
  color: #259D7F;
  /* margin-top: 0px; */
}


#menu-student {
  display: none;
}
#menu-student .one, #menu-student .two, #menu-student .three {
  background: #ffffff;
}
#menu-student div {
  width: 100%;
  height: 2px;
  margin: 4px auto;
  transition: all 0.3s;
  backface-visibility: hidden;
  border-radius: 4px;
}

#menu-student.on .one {
  transform: rotate(45deg) translate(2px, 5px);
}

#menu-student.on .two {
  opacity: 0;
}

#menu-student.on .three {
  transform: rotate(-45deg) translate(4px, -7px);
}
@media (min-width: 768px){
  .dl-horizontal {
    float: right;
  }
  .dl-horizontal dt {
    float: left;
    width: 110px;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 200;
  }
  .dl-horizontal dd {
    margin-left: 136px;
    font-weight: 600;
  }
  .box {
    padding: 0 !important;
  }

}

@media screen and (max-width: 600px) {
  #menu-student {
    width: 23px;
    height: 30px;
    cursor: pointer;
    display: block;
    margin-top: 12px;
  }

  .menu-student>ul{
    display: none;
  }
  .total-process h3 {
    font-size: 19px;
  }
  .process-total {

  }
  .bordered {
    margin: 15px;
  }
  .box-header>.box-tools {
    position: inherit;
  }
  .dashboard-student-menu {
    width: 150%;
  }
  .table {

  }
  .label {
    white-space: normal !important;
  }
  .single-post {
    height: 100px;
  }
  .consultant h2, .social h2 {
    margin-top: 20px;
  }
  .btn-pay {
    width: 80px;
  }
  table.table-item {
    margin: 5px 0;
  }
  .dl-horizontal dd {
    margin-left: 180px;
  }
  .dl-horizontal dt {
    float: left;
    width: 160px;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 800;
  }
  .total {
    height: 100%;
  }
  .attachment-info {
    width: 100%;
  }
}
.border-grey-2 {
  border: 1px solid #888888;
}
.f-14 * {
  font-size: 14px !important;
}
.f-12 {
  font-size: 12px !important;
}
.student-image:hover img::after {
  background: #000;
}
.color-888 {
  color: #888888 !important;
}
.current img {
  width: 75px;
}
.account {
  /*margin: 10px 0;*/
}

#toggle {
  width: 23px;
  height: 30px;
  position: absolute;
  left: 23px;
  top: 16px;
  z-index: 1000;
  cursor: pointer;
}
#toggle div {
  width: 100%;
  height: 2px;
  margin: 4px auto;
  transition: all 0.3s;
  backface-visibility: hidden;
  border-radius: 4px;
}
#toggle .one, #toggle .two, #toggle .three {
  background: #259D7F;
}
#toggle.on .one {
  transform: rotate(45deg) translate(2px, 5px);
}
#toggle.on .two {
  opacity: 0;
}
#toggle.on .three {
  transform: rotate(-45deg) translate(3px, -7px);
}
.menu-mobile {
  width: 100%;
  height: 100%;
  margin: 0;
  background: linear-gradient(#fff, #FDFDFD, #F7F7F7);
  position: fixed;
  color: #fff;
  padding-top: 49px;
  z-index: 99999;
  display: none;
  top:0;
}
.menu-mobile-list {
  color: #259D7F;
  text-align: center;
}
.menu-mobile-list li {
  padding: 10px;
}
.menu-mobile-list li span{
  font-size: 19px;
  font-weight: 500;
}
.menu-mobile-list li .icon-menu{
  display: inline-block;
  padding: 0 12px;
}
.menu-mobile-list li .icon-menu i{
  font-size: 20px;
}
.menu-mobile-list li.active a {
  color: #d9e300;
}
.account img{
  width: 65px;
  height: auto;
  display: block;
  border-radius: 35px;
  margin: 0 auto;
}
.header-dashboard h1 {
  /*text-transform: uppercase;*/
  padding-top: 16px;
  padding-bottom: 0;
  font-size: 30px;
}
@media only screen and (max-width: 768px) {
  .menu-student-mobile {
    display: block;
  }

}
.active-fixed {
  background: #31708f !important;
  color: #ffffff !important;
}

.warning {

}

.no-border {
  border: 0 !important;
}
.warning > .icon {
  display: inline-block;
  width: 57px;
}
.warning > .icon i{
  font-size: 38px;
  color: #f16523;
  padding: 6px;
}
.warning > .text {
  display: inline-block;
  width: 60%;
}
.list-agreement {
  margin-left: 16px;
}
.list-agreement > li {
  list-style-type: disc;
  margin-bottom: 10px;
}
span.uppercase {
  text-transform: uppercase;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px
}

.pagination>li {
  display: inline
}

.pagination>li>a,.pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd
}

.pagination>li:first-child>a,.pagination>li:first-child>span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px
}

.pagination>li:last-child>a,.pagination>li:last-child>span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px
}

.pagination>li>a:focus,.pagination>li>a:hover,.pagination>li>span:focus,.pagination>li>span:hover {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd
}

.pagination>.active>a,.pagination>.active>a:focus,.pagination>.active>a:hover,.pagination>.active>span,.pagination>.active>span:focus,.pagination>.active>span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7
}

.pagination>.disabled>a,.pagination>.disabled>a:focus,.pagination>.disabled>a:hover,.pagination>.disabled>span,.pagination>.disabled>span:focus,.pagination>.disabled>span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd
}

.pagination-lg>li>a,.pagination-lg>li>span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333
}

.pagination-lg>li:first-child>a,.pagination-lg>li:first-child>span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px
}

.pagination-lg>li:last-child>a,.pagination-lg>li:last-child>span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px
}

.pagination-sm>li>a,.pagination-sm>li>span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5
}

.pagination-sm>li:first-child>a,.pagination-sm>li:first-child>span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px
}

.pagination-sm>li:last-child>a,.pagination-sm>li:last-child>span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px
}
